<i18n>
{
  "en": {
    "monitoredStructure": "Structure"
  },
  "it": {
    "monitoredStructure": "Struttura"
  }
}
</i18n>

<template>
  <div id="drselect" class="noselect">
    <ModalCalendar v-if="showCalendar && dateRange[0] && dateRange[1]" :datarange="[dateRange[0], dateRange[1]]" @close="showCalendar = false" @update="updateDate" @range-selected="rangeSelected" @dayclick="$emit('dayclick', $event)" class="modal-cal" id="cal-events" :maxDate="maxDate" :minDate="minDate" />
    <!--  -->
    <div id="struct-select" v-if="availableStructures && selectedStructure">
      <h2 id="struct-name">{{ $t("monitoredStructure") }}:</h2>
      <v-select class="generic-select transparent-select" :options="availableStructures" label="name" v-model="actualStructure"  @input="onChangeStructure"></v-select>
    </div>

    <div @click="calendarHandler" id="calendar-group" class="info-group" v-if="initDateRange">
      <p class="info-btn">{{ $t("message.dateRange") }}:</p>
      <h2 class="info-btn">
        {{ this.formattedDate[0] + " - " + this.formattedDate[1] }}
      </h2>
      <OpenIndicator :class="[showCalendar ? 'up' : '', 'open-indicator']" />
    </div>
    <div v-else>
    </div>

    <!-- Relative Switch -->
    <div id="relative-switch" v-if="relativeMode != undefined && compensable">
      <p class="info-btn" :class="{ textdisabled: !compensable }">{{ $t("message.dataCompensation") }}:</p>
      <p class="info-btn" :class="{ textdisabled: !compensable }">
        {{ this.relativeMode ? $t("message.activeF") : $t("message.inactiveF") }}
      </p>
      <label class="switch">
        <input type="checkbox" :disabled="!compensable" v-model="tempRelativeMode" />
        <span class="slider round" :class="{ colordisabled: !compensable }"></span>
      </label>
    </div>
  </div>
</template>
<script>
import {checkIfPageShoulBeShow} from "../../helpers/toolHandler"
import ModalCalendar from "./ModalCalendar.vue";
import OpenIndicator from "./OpenIndicator.vue";
import router from "../../router"
import "vue-select/dist/vue-select.css";

export default {
  name: "DateRangeSelect",
  components: {
    ModalCalendar,
    OpenIndicator,
  },
  props: {
    initDateRange: Array,
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: () => new Date(),
    },
    relativeMode: {
      type: Boolean,
      default: undefined,
    },
    compensable: Boolean,
    selectedStructure: Object,
    availableStructures: Array,
  },
  data() {
    return {
      dateRange: [],
      actualDateRange: [],
      showCalendar: false,
      tempRelativeMode: true,
    };
  },
  computed: {
    formattedDate: function () {
      // Data formattata
      let firstDate;
      let lastDate;
      if (this.dateRange[0] && this.dateRange[1]) {
        firstDate = this.formatDate(this.dateRange[0]);
        lastDate = this.formatDate(this.dateRange[1]);
      }
      return [firstDate, lastDate];
    },
    actualStructure: {
      // getter
      get: function () {
        return this.selectedStructure;
      },
      // setter
      set: function (newValue) {
        if (newValue) {
          this.$emit("structure-selected", newValue);
        }
      },
    },
  },
  methods: {
    calendarHandler: function () {
      this.showCalendar = !this.showCalendar;
    },
    updateDate: function () {
      this.$emit("date-selected", this.dateRange);
      this.showCalendar = false;
    },
    formatDate: function (date) {
      let day = date.getDate();
      let year = date.getFullYear();
      let month = date.toLocaleString("default", { month: "short" });
      return day + " " + month + " " + year;
    },
    rangeSelected: function (newDateRange) {
      this.$emit('range-selected', newDateRange)
      this.dateRange = newDateRange.map((dr) => new Date(dr));
    },
    onChangeStructure(structure){
      if(!checkIfPageShoulBeShow(this.$route.name, structure)){
        router.push('/dashboard')
      }

    }
  },
  watch: {
    initDateRange(newDateRange) {
      this.dateRange = newDateRange;
    },
    tempRelativeMode(newValue) {
      this.$emit("set-relative-mode", newValue);
    },
  },
  mounted() {
    this.dateRange = this.initDateRange;
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
h2 {
  font-weight: 500;
}
#struct-select {
  display: flex;
  white-space: nowrap;
  align-items: center;
}
#struct-name {
  padding-bottom: 2px;
}
.info-btn {
  margin-right: 8px;
}
#drselect {
  /* height: 80px; */
  width: 100%;
  min-width: max-content;
  background-color: rgb(40, 42, 59);
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: space-between;
  padding: 6px 60px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: solid 1px rgb(80, 84, 105);
}
/* .generic-select{
  width: 180px;
} */
/* Divisori */
.horiz-divider {
  grid-row: 2;
  height: 2px;
  background-color: rgba(80, 84, 105, 1);
  /*   margin-top: 15px;
  margin-bottom: 10px; */
  width: -webkit-fill-available;
  /* min-width: 1080px; */
  margin-left: 120px;
  margin-right: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  box-sizing: border-box;
}

.vert-divider {
  margin-right: 3vw;
  width: 2px;
  height: 40px;
  background-color: rgba(80, 84, 105, 1);
  position: relative;
  justify-self: end;
}

#event-info {
  grid-column-start: 1;
  grid-column-end: 4;
  justify-self: flex-start;
  /* align-self: center; */
  margin-left: 120px;
  display: flex;
  align-items: center;
}

#event-info > h2 {
  margin: 0;
  text-align: left;
  margin-right: 4vw;
  min-width: 300px;
}

@media all and (max-width: 1799px) and (min-width: 300px) {
  #event-info {
    margin-left: 30px;
  }
  .horiz-divider {
    margin-left: 30px;
  }
}

.info-group {
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 450px;
  cursor: pointer;
}

.info-group > h2 {
  margin: 0;
  margin-left: 10px;
}
.info-group > p {
  margin: 0;
}

.open-indicator {
  padding: 5px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}

.up {
  transform: rotate(180deg);
}

#cal-events {
  position: absolute;
  right: 30%;
  top: 83px;
  max-width: 770px;
  background-color: rgb(45, 48, 65);
  z-index: 12;
}

.modal-cal {
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(91, 96, 118);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
select {
  padding: 0;
  padding-right: 20px;
  margin: 5px 0;
  margin-left: 5px;
  /* font-size: 1.1em; */
  font-weight: 700;
}
.data-info {
  height: 40px;
  background-color: rgb(30, 31, 43);
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.data-label {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  width: 70%;
}
.data-value {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

@media all and (max-width: 1499px) and (min-width: 300px) {
  /*   .overview {
    margin-top: 25px;
  } */
  .bridge-info-block {
    grid-template-columns: 70% 30%;
  }
}
#calendar-group {
  justify-self: center;
}
#relative-switch {
  display: flex;
  justify-self: end;
}
</style>