<template>
  <transition name="modal-info-fade">
    <div class="modal-info-backdrop">
      <div class="modal-info" :style="classe" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <div class="calendar-wrapper" v-click-outside="onClickOutside">
          <a @click="closeModal" class="close"></a>

          <!-- Indicatori del range selezionato -->
          <div class="days-indicator" @click.stop>
            <div class="day-box">
              <h2>{{ $t("message.startDate") }}</h2>
              <div class="static-picker">
                <p>{{ formattedRange[0] }}</p>
                <img class="calendar-img" src="../../../public/assets/calendar.svg" />
              </div>
            </div>
            <div class="day-box">
              <h2>{{ $t("message.endDate") }}</h2>
              <div class="static-picker">
                <p>{{ formattedRange[1] }}</p>
                <img class="calendar-img" src="../../../public/assets/calendar.svg" />
              </div>
            </div>
          </div>

          <!-- Bottoni scelta range temporale -->
          <div class="days-control" @click.stop>
            <button :disabled="!checkIfDateRangeAvailable(7)" class="unstyled-button" @click.prevent="daysRange = 7" :class="{ active: this.daysRange === 7 }">
              {{ $t("message.last7Days") }}
            </button>
            <button :disabled="!checkIfDateRangeAvailable(15)" class="unstyled-button" @click.prevent="daysRange = 15" :class="{ active: this.daysRange === 15 }">
              {{ $t("message.last15Days") }}
            </button>
            <button :disabled="!checkIfDateRangeAvailable(30)" class="unstyled-button" @click.prevent="daysRange = 30" :class="{ active: this.daysRange === 30 }">
              {{ $t("message.last30Days") }}
            </button>
            <button :disabled="!checkIfDateRangeAvailable(84)" class="unstyled-button" @click.prevent="daysRange = 84" :class="{ active: this.daysRange === 84 }">
              {{ $t("message.last12Weeks") }}
            </button>
            <button :disabled="!checkIfDateRangeAvailable(180)" class="unstyled-button" @click.prevent="daysRange = 180" :class="{ active: this.daysRange === 180 }" v-show="false">
              {{ $t("message.last6Months") }}
            </button>
            <button :disabled="!checkIfDateRangeAvailable(360)" class="unstyled-button" @click.prevent="daysRange = 360" :class="{ active: this.daysRange === 360 }" v-show="false">
              {{ $t("message.last12Months") }}
            </button>
          </div>
          <div class="horizontal-divider"></div>

          <!-- Main Date Picker Component-->
          <div class="warp" @click.stop>
            <vc-date-picker
              :locale="this.$root.$i18n.locale"
              ref="calendar"
              v-model="range"
              mode="date"
              :input-debounce="500"
              color="blue"
              is-dark
              :is-inline="true"
              :rows="1"
              :columns="$screens({ default: 2, lg: 2 })"
              :max-date="maxDate"
              :min-date="minDate"
              @dayclick="onDayClick"
              is-range
            />
          </div>
          <!-- Bottoni di controllo -->
          <div class="bottom-buttons">
            <button class="button" @click="closeModal">{{ $t("message.undo") }}</button>
            <button class="button" @click="updateDateRange">{{ $t("message.update") }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from "moment";
import dates from "../../helpers/dates";

export default {
  name: "modal-calendar",

  props: {
    datarange: Array,
    classe: String,
    minDate: { type: Date, default: null },
    maxDate: {
      type: Date,
      default: () => new Date(),
    }
  },
  data() {
    return {
      range: {
        start: this.datarange[0], // Jan 16th, 2018
        end: this.datarange[1], // Jan 19th, 2018
      },
      daysRange: null,
    };
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    closeModal: function() {
      this.$emit("close");
    },
    updateDateRange: function() {
      this.$emit("update");
    },
    /* Restituisce un array [today - days, today ] */
    dateRangeFromDays: function(days) {
      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - days);
      let lastDate = new Date();
      return [firstDate, lastDate];
    },
    checkIfDateRangeAvailable(numberOfDaysAgo) {
      if (!this.minDate) {
        return true;
      } else {
        let d = new Date();
        d.setDate(d.getDate() - numberOfDaysAgo);
        return d >= new Date(this.minDate);
      }
    },
    onDayClick(event){
      this.daysRange = null;
      this.$emit("dayclick",event);
    }
  },
  computed: {
    firstDate: function() {
      return moment(this.range["start"]);
    },
    lastDate: function() {
      return moment(this.range["end"]);
    },
    formattedRange: function() {
      return [dates.ddmmyyyyFormat(this.range.start), dates.ddmmyyyyFormat(this.range.end)];
    },
  },
  watch: {
    daysRange: {
      /* Aggiorna il Date Range quando cambiano i giorni selezionati */
      handler: function(value) {
        if (value) {
          console.log(value)
          let newRange = this.dateRangeFromDays(value);
          this.range.start = newRange[0];
          this.range.end = newRange[1];
          // Get the calendar ref
          this.$refs.calendar.$refs.calendar.showPageRange(newRange[0]);
          let fixedDateRange = dates.getEntireDateRange(this.range.start, this.range.end);
          this.range = {
            start: fixedDateRange[0],
            end: fixedDateRange[1]
          }
          this.$emit("range-selected", [this.firstDate, this.lastDate]);
        }
      },
    },
    datarange: {
      handler: function(val) {
        this.range.start = val[0];
        this.range.end = val[1];
      },
    },
    range: {
      handler: function() {
        let fixedDateRange = dates.getEntireDateRange(this.range.start, this.range.end);
        this.range.start = fixedDateRange[0];
        this.range.end = fixedDateRange[1];
        this.$emit("range-selected", [this.firstDate, this.lastDate]);
      },
    },
  },
};
</script>
<style scoped>
/* MODAL STUFF */
.modal-info-backdrop {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  z-index: 999;
  padding: 20px;
}
.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 86.6px 50px 0;
  border-color: transparent #2d3041 transparent transparent;
}

.modal-info-header {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.modal-info-header {
  padding-top: 10px;
  color: #4aae9b;
  justify-content: space-between;
  background-color: rgb(45, 48, 65);
}

.modal-info-body {
  position: relative;
  background-color: rgb(45, 48, 65);
}

.btn-close {
  border: none;
  padding: 50px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  background: #4aae9b;
  border-radius: 2px;
  width: 400px;
  height: 40px;
  font-size: 20px;
}

.modal-info-fade-enter,
.modal-info-fade-leave-active {
  opacity: 0;
}

.modal-info-fade-enter-active,
.modal-info-fade-leave-active {
  transition: opacity 0.3s ease;
}

h2 {
  font-size: 1em;
  color: white;
  margin: 0;
}

/* CALENDAR STUFF */

/* Root Wrapper */
.calendar-wrapper {
  /* height: 410px; */
  width: fit-content;
  min-height: 0px;
  display: flex;
  border-radius: 10px;
  z-index: 999999999;
}

/* Calendar Component */
.vc-container {
  background-color: rgba(80, 84, 105, 0) !important;
  border: hidden !important;
  margin-top: 125px;
  margin-left: 15px;
}

/* Bottoni scelta giorni */
.days-control {
  display: grid;
  justify-items: baseline;
  /* margin-left: 22px; */
  margin-right: 15px;
  height: 280px;
  margin-top: 30px;
  grid-template-rows: repeat(4, min-content);
}

.unstyled-button {
  color: gray;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 16px 0;
  background: none;
  cursor: pointer;
}

.unstyled-button:focus {
  outline: 0 !important;
}
.unstyled-button:disabled{
  opacity: 0.5;
}

.active {
  color: white;
}
.static-picker > p {
  font-weight: 600;
  margin: auto;
  left: -20px;
  position: relative;
}

/* Visualizzazione date scelte */
.days-indicator {
  position: absolute;
  min-height: 0px;
  top: 42px;
  left: 248px;
  width: 380px;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.day-box {
  margin-right: auto;
  text-align: left;
}
.static-picker {
  width: 160px;
  height: 38px;
  background-color: rgba(80, 84, 105, 1);
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: default;
  min-width: 0%;
  min-height: 0px;
}
.calendar-img {
  position: absolute;
  margin-left: 130px;
}

/* Utils */
.horizontal-divider {
  margin-top: 20px;
  margin-bottom: auto;
  height: 400px;
  width: 2px;
  background-color: rgba(80, 84, 105, 1);
}

/* Control Buttons */
.button {
  width: 118px;
  font-size: 1em;
  padding: 4px;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}

.bottom-buttons {
  display: flex;
  position: absolute;
  top: 405px;
  right:0px;
}

/* Close Icon */
.close {
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 9;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>
