export function hasRailTool(structure = {}){
    return structure && structure.railEnabled
}

export function hasOverlaysTool(structure = {}){
    return structure && structure.overlaysEnabled
}

export function checkIfPageShoulBeShow(pageName = null, structure = {}){
    switch (pageName) {
        case 'overlays':
            return hasOverlaysTool(structure)
        case 'rails':
            return hasRailTool(structure)
        default:
            return true
    }
}